<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <!-- <el-col>
          <el-button
            plain
            class="add-column-btn bbox"
            @click="gotoDetail('')"
            type="primary"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col> -->
        
        <el-col>
          <div class="flex align-center justify-end header-search-box">
            <el-input
              placeholder="请输入活动标题"
              clearable
              v-model="queryInfo.condition.title"
              class="header-search-input ml10"
            >
            </el-input>
            <!-- <el-select
              v-model="value"
              placeholder="请选择状态"
              @change="selectCheck"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
              <el-button
                slot="append"
                icon="el-icon-search"
                class="textblue searchBtn"
                @click="search()"
              ></el-button>
            
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
      <el-table-column label="省" align="center" prop="省">
          <template slot-scope="scope">
            <span>{{ scope.row.provinceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="市" align="center" prop="市">
          <template slot-scope="scope">
            <span>{{ scope.row.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="区" align="center" prop="区">
          <template slot-scope="scope">
            <span>{{ scope.row.districtName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="街道" align="center" prop="街道">
          <template slot-scope="scope">
            <span>{{ scope.row.streetName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="社区" align="center" prop="社区">
          <template slot-scope="scope">
            <span>{{ scope.row.communityName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="小区" align="center" prop="小区">
          <template slot-scope="scope">
            <span>{{ scope.row.villageName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="用户名称" align="center" prop="用户名称" >
          <template slot-scope="scope">
            <span>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="封面图" align="center" prop="封面图">
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.coverUrl }}</span> -->
            <el-image
              :preview-src-list="[scope.row.coverUrl]"
              :src="scope.row.coverUrl"
              fit="contain"
              style="width:60px;max-height:80px"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="标题"
          align="center"
          prop="标题"
          min-width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="地址" align="center" prop="地址" min-width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="开始时间" align="center" prop="开始时间" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.startDt | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" align="center" prop="结束时间" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.endDt | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动人数" align="center" prop="活动人数">
          <template slot-scope="scope">
            <span>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="验证码" align="center" prop="验证码">
          <template slot-scope="scope">
            <span>{{ scope.row.verCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动描述" align="center" prop="活动描述" min-width="400">
          <template slot-scope="scope">
            <span>{{ scope.row.describeInfo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="创建时间" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.createDt | dataFormat }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="状态" align="center" prop="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === '0'">未审核</span>
            <span v-if="scope.row.status === '1'">审核通过</span>
            <span v-if="scope.row.status === '2'">审核失败</span>
            <span v-if="scope.row.status === '3'">活动失效</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="失败原因" align="center" prop="失败原因">
          <template slot-scope="scope">
            <span>{{ scope.row.failReason }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="是否是热门活动"
          align="center"
          prop="是否是热门活动"
        >
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.isHot }}</span> -->
            <span v-if="scope.row.isHot === '0'">不是</span>
            <span v-if="scope.row.isHot === '1'">是</span>
          </template>
        </el-table-column>
        <el-table-column label="标签" align="center" prop="标签">
          <template slot-scope="scope">
            <span>{{ scope.row.label }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="活动进行状态"
          align="center"
          prop="活动进行状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.statusing === '0'">报名中</span>
            <span v-if="scope.row.statusing === '1'">进行中</span>
            <span v-if="scope.row.statusing === '2'">已结束</span>
            <span v-if="scope.row.statusing === '3'">解散</span>
          </template>
        </el-table-column>
        <el-table-column label="活动类型" align="center" prop="活动类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type === '1'">公开活动</span>
            <span v-if="scope.row.type === '2'">私密活动</span>
            <span v-if="scope.row.type === '3'">惠购活动</span>
          </template>
        </el-table-column>
        <el-table-column label="联系电话" align="center" prop="联系电话" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="配送单价" align="center" prop="配送单价">
          <template slot-scope="scope">
            <span>{{ scope.row.deliveryFee }}</span>
          </template>
        </el-table-column>
        <el-table-column label="配送费上限" align="center" prop="配送费上限">
          <template slot-scope="scope">
            <span>{{ scope.row.limitDeliveryFee }}</span>
          </template>
        </el-table-column>

        
        <el-table-column
          label="发布活动来源"
          align="center"
          prop="发布活动来源"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.source === '1'">YOpro</span>
            <span v-if="scope.row.source === '2'">智慧社区</span>
          </template>
        </el-table-column>

        <el-table-column label="供应商名称" align="center" prop="供应商名称">
          <template slot-scope="scope">
            <span>{{ scope.row.supplierName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否创建群聊"
          align="center"
          prop="是否创建群聊"
        >
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.isGroup }}</span> -->
            <span v-if="scope.row.isGroup === '0'">未创建</span>
            <span v-if="scope.row.isGroup === '1'">创建</span>
            <!-- <span v-if="scope.row.isGroup==='2'">未创建</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="限制几星参加"
          align="center"
          prop="限制几星参加"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.star}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id, scope.row.userCode)"
              ></i>
            </el-tooltip> -->
            <el-tooltip
              class="item"
              effect="dark"
              content="置顶"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="toTop(scope.row)"
              ></i>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status == '0'"
              class="item"
              effect="dark"
              content="通过"
              placement="bottom"
            >
              <i
                class="el-icon-circle-check textblue f16 ml20"
                @click="success(scope.row)"
              ></i>
            </el-tooltip>

            <el-tooltip
              v-if="scope.row.status == '0'"
              class="item"
              effect="dark"
              content="拒绝"
              placement="bottom"
            >
              <i class="el-icon-circle-close textblue f16 ml20" @click="fail(scope.row)"></i>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >

              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="活动导出"
          align="center"
          prop="活动导出"
        >
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.star}}</span> -->
            <el-button plain class="add-column-btn" style="width: 60px;padding: 0;" type="primary" @click="getExport(scope.row.activityCode,scope.row.title)">
              <i class="el-icon-plus f16"></i> 导出
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      options: [
        { label: "待审核", value: "0" },
        { label: "审核通过", value: "1" },
        { label: "审核失败", value: "2" }
      ],
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          streetCode: localStorage.getItem("streetCode"),
          villageCode: localStorage.getItem("villageCode"),
          communityCode: localStorage.getItem("communityCode"),
          gridManageCode: localStorage.getItem("gridManageCode"),
          source:'2',
        },
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: "",
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
    };
  },
  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getExport(activityCode,title){
      var that = this;
      this.$http.post("/memberActivity/exportExcel", {activityCode,title}).then((res) => {
        if (res.data.code == 200) {
          window.location.href = res.data.data
          that.$notify.success({
            title: "提示",
            message: "导出成功"
          });
        } else {
          that.$notify.error({
            title: "提示",
            message: res.data.message
          });
        }
        return
      });
    },
    selectCheck(e) {
      this.queryInfo.condition.status = e;
    },

    //条件查询
    search() {
      // this.queryInfo.condition.title = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    toTop(info){
      let data = {
        id: info.id,
        isHot:'1',
      };
      this.updateMemberActivity(data);
    },
    //审核通过
    success(info) {
      let data = {
        id: info.id,
        status: "1",
      };
      this.updateMemberActivity(data);
    },
    //审核不通过
    fail(info) {
      let data = {
        id: info.id,
      };
      this.$prompt("请输入拒绝原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        data.failReason = value;
        data.status = "2";
        this.updateMemberActivity(data);
      });
    },
        //根据id修改
    updateMemberActivity(row) {
      this.$http.post("/memberActivity/update", row).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
          this.search();
        } else {
          this.$notify.console.error({
            title: "提示",
            message: res.data.message,
            showClose: true,
          });
        }
      });
    },
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/memberActivity/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize,
        },
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/memberActivity/delete", { id: id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/memberActivity/list", that.queryInfo)
        .then(function (response) {
          console.log("获取列表",response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>

